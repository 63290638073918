import PropTypes from 'prop-types';
import {
  TableContainer, Table, TableHead, TableBody, TableRow, TableCell,
  Box, Button, Paper,
} from '@mui/material';
import { useGetInstallationAuditLogQuery } from '../api/package-manager-api';

const AuditLog = ({ installationId }) => {
  const { data: auditLog, refetch } = useGetInstallationAuditLogQuery({
    installationId,
    limit: 20,
  });

  return auditLog && (
    <TableContainer component={Paper} elevation={1}>
      <Box display='flex' justifyContent='right'>
        <Button
          sx={{ fontSize: '4ex' }}
          onClick={() => refetch()}
        >
          &#8634;
        </Button>
      </Box>
      <Table>
        <TableHead>
          <TableRow>
            <TableCell>Time</TableCell>
            <TableCell>Event</TableCell>
            <TableCell>Bundle</TableCell>
            <TableCell>User</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {auditLog.entries.map((entry) => (
            <TableRow key={entry.timestamp}>
              <TableCell>
                {new Date(entry.timestamp).toLocaleString(window.navigator.language)}
              </TableCell>
              <TableCell>{entry.eventId}</TableCell>
              <TableCell>{entry.bundle && `${entry.bundle.name} v${entry.bundle.version}`}</TableCell>
              <TableCell>{entry.user}</TableCell>
            </TableRow>
          ))}
        </TableBody>
      </Table>
    </TableContainer>
  );
};

AuditLog.propTypes = {
  installationId: PropTypes.string.isRequired,
};

export default AuditLog;
